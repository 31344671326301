exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-gallery-inner-js": () => import("./../../../src/pages/BlogGalleryInner.js" /* webpackChunkName: "component---src-pages-blog-gallery-inner-js" */),
  "component---src-pages-blog-gallery-js": () => import("./../../../src/pages/BlogGallery.js" /* webpackChunkName: "component---src-pages-blog-gallery-js" */),
  "component---src-pages-blog-news-inner-js": () => import("./../../../src/pages/BlogNewsInner.js" /* webpackChunkName: "component---src-pages-blog-news-inner-js" */),
  "component---src-pages-blog-news-js": () => import("./../../../src/pages/blog-news.js" /* webpackChunkName: "component---src-pages-blog-news-js" */),
  "component---src-pages-calender-js": () => import("./../../../src/pages/calender.js" /* webpackChunkName: "component---src-pages-calender-js" */),
  "component---src-pages-catering-menu-js": () => import("./../../../src/pages/CateringMenu.js" /* webpackChunkName: "component---src-pages-catering-menu-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donation-form-js": () => import("./../../../src/pages/DonationForm.js" /* webpackChunkName: "component---src-pages-donation-form-js" */),
  "component---src-pages-event-inner-js": () => import("./../../../src/pages/EventInner.js" /* webpackChunkName: "component---src-pages-event-inner-js" */),
  "component---src-pages-hall-booking-js": () => import("./../../../src/pages/HallBooking.js" /* webpackChunkName: "component---src-pages-hall-booking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-past-event-js": () => import("./../../../src/pages/PastEvent.js" /* webpackChunkName: "component---src-pages-past-event-js" */),
  "component---src-pages-pooja-inner-js": () => import("./../../../src/pages/PoojaInner.js" /* webpackChunkName: "component---src-pages-pooja-inner-js" */),
  "component---src-pages-pooja-inner-outside-js": () => import("./../../../src/pages/PoojaInnerOutside.js" /* webpackChunkName: "component---src-pages-pooja-inner-outside-js" */),
  "component---src-pages-pooja-inside-temple-js": () => import("./../../../src/pages/PoojaInsideTemple.js" /* webpackChunkName: "component---src-pages-pooja-inside-temple-js" */),
  "component---src-pages-pooja-outside-temple-js": () => import("./../../../src/pages/PoojaOutsideTemple.js" /* webpackChunkName: "component---src-pages-pooja-outside-temple-js" */),
  "component---src-pages-tecacomitee-js": () => import("./../../../src/pages/tecacomitee.js" /* webpackChunkName: "component---src-pages-tecacomitee-js" */),
  "component---src-pages-temple-deities-js": () => import("./../../../src/pages/temple-deities.js" /* webpackChunkName: "component---src-pages-temple-deities-js" */),
  "component---src-pages-temple-history-js": () => import("./../../../src/pages/temple-history.js" /* webpackChunkName: "component---src-pages-temple-history-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/ThankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-upcoming-event-js": () => import("./../../../src/pages/UpcomingEvent.js" /* webpackChunkName: "component---src-pages-upcoming-event-js" */)
}

